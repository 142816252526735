import './changelog.css';
import '../theme/theme.css';
import ThemeToggle from '../theme/theme';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Changelog = () => {
  const [themeMode, setThemeMode] = useState('light');
  const navigate = useNavigate();

  const handleThemeToggle = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--theme-light', 'black');
      root.style.setProperty('--theme-light-alpha', 'black');
      root.style.setProperty('--theme-dark', 'white');
      root.style.setProperty('--theme-dark-alpha', 'rgba(201, 220, 30, 0.962)');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, black, rgb(100, 100, 100))');
    } else {
      root.style.setProperty('--theme-light', 'white');
      root.style.setProperty('--theme-light-alpha', 'white');
      root.style.setProperty('--theme-dark', 'black');
      root.style.setProperty('--theme-dark-alpha', 'black');
      root.style.setProperty('--background-gradient', 'linear-gradient(to top, rgb(100, 100, 100),white)');
    }
  }, [themeMode]);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className='changelog-background'>
      <div className="theme-toggle">
        <ThemeToggle onChange={handleThemeToggle} checked={themeMode === 'dark'} />
      </div>
      <div className="back-button" onClick={handleBackClick}>
        <svg viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </div>
      <h1 className='changelog-title'>Changelog :</h1>
      <ul className='changelog-items'>
      <li>0.0.01 (07-22-24) - This is the first "official" release. The features are finally stable enough to advertise. This is an img-2-3D app in which the user can submit an image of a face and in return they receive a Vitruvian based on that face.</li>
      <li>0.0.02 (02-12-25) - The majority of these changes are related to the ui. This update opens up the editor to fill up the entire page rather than keep the elements contained. It also removes the "zoom" slider in favor of more simplistic tools and more generator editor tools. Generally things are more stable, especially for the generator's editor and the 3D editor.</li>
      </ul>
    </div>
  );
};

export default Changelog;
